import React from "react";
import {
  Paper,
  Tabs,
  Tab,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AllInbox, FlightTakeoff, DoneOutline } from "@material-ui/icons";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  form: {
    "& > *": {
      margin: theme.spacing(1),
      width: '100%',
    },
    root:{
        flexGrow: 1,
    }
  },
}));

function SignUp() {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = (data) => console.log(data);
  console.log(errors, "errs");

  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container justify="center" alignItems="center" className={classes.root}>
      <Grid
        item
        xs={12}
        style={{ padding: 40, textAlign: "center", background: "#e4e4e4" }}
      >
        <Typography variant="h4">Create your Account</Typography>
      </Grid>

      
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          {/* <Grid xs={12} md={6}> */}
            {/* <TextField
              label="First name"
              name="Firstname"
              variant="outlined"
              ref={register({ required: true, maxLength: 20 })}
            /> */}

            <input type="text" name="First name" ref={register({ required: true, maxLength: 30 })}/>
          {/* </Grid> */}
          <Grid xs={12} md={6}>
            <TextField
              label="Last name"
              name="Last name"
              variant="outlined"
              type="text"
              ref={register({ required: true, maxLength: 30 })}
            />
          </Grid>

          {/* <Grid xs={12}> */}
            {/* <TextField
              label="Email"
              name="Email"
              type="email"
              variant="outlined"
              ref={register({ required: true, maxLength: 30 })}
            /> */}
          {/* </Grid> */}
          <input type="submit" />
        </form>
      </Grid>
    // </Grid>
  );
}
export default SignUp;
