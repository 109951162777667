import React from 'react';
import {Paper, Tabs, Tab, Grid} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { AllInbox, FlightTakeoff, DoneOutline } from '@material-ui/icons';

function Packages() {
    const useStyles = makeStyles({
        root: {
          flexGrow: 1,
        },
      });

    const classes = useStyles();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
    

    return(
        <div>
             <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        
        textColor="primary"
        variant="fullWidth"
        centered
      >
        <Tab label="All" />
        <Tab label="In-Transit"  />
        <Tab label="Ready" />
      </Tabs>
    </Paper>
    <Grid container>
        <Grid item xs={12}>
            {value}
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
            <p>OK - 88787</p>
        </Grid>
    </Grid>
        </div>
    )
}
export default Packages;
