import React from "react";
import { myStyles } from "../utils/config";
import {
  Grid,
  Divider,
  Paper,
  Typography,
  Button,
  Avatar,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";
import {
  Money,
  DoneOutline,
  MonetizationOn,
  AddBox,
  FlightTakeoff,
} from "@material-ui/icons";
import ImageIcon from "@material-ui/icons/Image";
import MessageBoard from "../components/MessageBoard";
import Package from "../components/Package";
import PackageItem from "../components/PackageItem";
import Mailbox from "../components/Mailbox";


function Dashboard() {
  const classes = myStyles();
  return (
    <div>
      <Mailbox />
      <Grid container spacing={1}>
        <Grid item xs={6} lg={3} className={classes.packStatusPanel}>
          <Paper>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: "#08AEEA",
                    backgroundImage:
                      "linear-gradient(0deg, #08AEEA 0%, #2AF598 100%)",
                  }}
                >
                  <DoneOutline />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<span className={classes.purpleTitle}>3</span>}
                secondary="Ready"
              />
            </ListItem>
          </Paper>
        </Grid>

        <Grid item xs={6} lg={3} className={classes.packStatusPanel}>
          <Paper>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: "#FBAB7E",
                    backgroundImage:
                      "linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)",
                  }}
                >
                  <FlightTakeoff />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<span className={classes.purpleTitle}>3</span>}
                secondary="In-Transit"
              />
            </ListItem>
          </Paper>
        </Grid>

        <Grid item xs={6} lg={3} className={classes.packStatusPanel}>
          <Paper>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: "#FFE53B",
                    backgroundImage:
                      "linear-gradient(147deg, #FFE53B 0%, #FF2525 74%)",
                  }}
                >
                  <MonetizationOn />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<span className={classes.purpleTitle}>$23,560</span>}
                secondary="Balance"
              />
            </ListItem>
          </Paper>
        </Grid>

        <Grid item xs={6} lg={3} className={classes.packStatusPanel}>
          <Paper>
            <ListItem>
              <ListItemAvatar>
                <Avatar
                  style={{
                    backgroundColor: "#21D4FD",
                    backgroundImage:
                      "linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)",
                  }}
                >
                  <Money />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<span className={classes.purpleTitle}>340</span>}
                secondary="Points"
              />
            </ListItem>
          </Paper>
        </Grid>
        {/* <Grid item xs={12}>
          <MessageBoard />
        </Grid>  */}
        <Grid item xs={12} style={{ paddingTop: 20 }}>
          <Typography component="span" variant="h5" className={classes.title}>
            My Packages
          </Typography>
          <div className={classes.section3} style={{ float: "right" }}>
            <Button color="primary">See all</Button>
          </div>
        </Grid>
        <Grid container>
          <Divider />
          {["received", "intransit", "ready"].map((value) => (
            <Package key={value} data={{ type: value }} />
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
export default Dashboard;
