import React, { useEffect, useState } from "react";
import { SvgIcon } from "@material-ui/core";
// import Icon from '@mdi/react';
// import { mdiPackageVariantClosed } from '@mdi/js';
import {
  Grid,
  CircularProgress,
  Chip,
  Typography,
  Avatar,
  Paper,
  List,
  Button,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Badge,
} from "@material-ui/core";
import {
  Receipt,
  DoneOutline,
  FlightTakeoff,
  LocalShippingTwoTone,
  LocalShipping,
} from "@material-ui/icons";
import { myStyles } from "../utils/config";
import { makeStyles } from "@material-ui/core/styles";

function Package(props) {
  const thisStyle = makeStyles(() => ({
    colorReceived: {
      color: "grey",
      // backgroundColor: "grey"
      borderColor: "grey",
      // color:"white"
    },
    colorInTransit: {
      color: "orange",
      borderColor: "orange",
      // backgroundColor: "orange"
      // color:"white"
    },
    colorReady: {
      color: "blue",
      // color:"white"
    },
    colorDelivered: {
      color: "green",
      // color:"white"
    },
    paper: {
      margin: 15,
      textAlign: "left",
      background: "white",
      color: "#2D0C57",
      // width:"100%"
      // color: theme.palette.text.secondary,
    },
    bigLabel: {
      color: "#2D0C57",
      fontSize: "1.9em",
      fontWeight: 600,
      backgroundColor: "#F4EDF1",
      padding: 3,
      borderRadius: 5,
    },
    bigLabel2: {
      color: "#2D0C57",
      fontSize: "1.2em",
      fontWeight: 600,
      backgroundColor: "#eafcf6",
      padding: 3,
      borderRadius: 5,
      border:"1px solid #ddd"
    },
  }));
  const classes = myStyles();
  const css = thisStyle();
  const [packageType, setPackageType] = useState("received");
  const InvetoryIcon = (
    <SvgIcon>
      <path d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M15,14H9v-2h6V14z M20,7H4V4h16V7z" />
    </SvgIcon>
  );
  const lb = (
    <SvgIcon style={{ verticalAlign: "text-bottom", width: 21 }}>
      <path
        stroke="#9586B0"
        fill="none"
        d="M12,3A4,4 0 0,1 16,7C16,7.73 15.81,8.41 15.46,9H18C18.95,9 19.75,9.67 19.95,10.56C21.96,18.57 22,18.78 22,19A2,2 0 0,1 20,21H4A2,2 0 0,1 2,19C2,18.78 2.04,18.57 4.05,10.56C4.25,9.67 5.05,9 6,9H8.54C8.19,8.41 8,7.73 8,7A4,4 0 0,1 12,3M12,5A2,2 0 0,0 10,7A2,2 0 0,0 12,9A2,2 0 0,0 14,7A2,2 0 0,0 12,5Z"
      />
    </SvgIcon>
  );

  const type = (value) => {
    switch (value) {
      case "received":
        return {
          css: css.colorReceived,
          icon: (
            <SvgIcon>
              <path d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M15,14H9v-2h6V14z M20,7H4V4h16V7z" />
            </SvgIcon>
          ),
        };
      case "intransit":
        return { css: css.colorInTransit, icon: <FlightTakeoff /> };
      case "ready":
        return { css: css.colorReady, icon: <Receipt /> };
      case "delivered":
        return { css: css.colorDelivered, icon: <DoneOutline /> };
      default:
        return { css: css.colorReceived, icon: "" };
    }
  };

  useEffect(() => {
    setPackageType(props.data.type);
  }, [props.data.type]);
  return (
    <Grid item xs={12} lg={6}>
      <Paper className={css.paper}>
        <div style={{ padding: 6, textAlign: "left" }}>
          <Grid container style={{ paddingTop: 15 }}>
            <Grid item xs={9}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 700, paddingLeft: 10 }}
              >
                FOOTWEAR, NIKE
              </Typography>
            </Grid>
            <Grid item xs={3} style={{textAlign:"right"}}>
              <span className={css.bigLabel2}>$19,000</span>
            </Grid>
          </Grid>
        </div>

        {/* <Divider variant="middle" /> */}
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <Avatar
              style={{
                color: "#2D0C57",
                position: "absolute",
                background: "none",
              }}
            >
              {<LocalShipping />}
            </Avatar>
            <CircularProgress
              variant="determinate"
              value={33}
              style={{ position: "absolute", color: "#24E7AA" }}
            />
          </ListItemAvatar>
          <Grid container style={{ paddingLeft: 10 }} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body">AMAZON</Typography>
              <div>
                <small>87925790342858943</small>
              </div>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              {/* <span style={{ color:"#2D0C57", fontSize:"1.8em", fontWeight:600}}> */}
              <Typography variant="body" className={css.bigLabel}>
                12
              </Typography>
              &nbsp;
              <small style={{ fontSize: "0.7em", color: "#9586A8" }}>LBS</small>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </ListItem>
        <Grid container style={{ padding: 10 }}>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Button color="primary">UPLOAD INVOICE</Button>
            <Button color="primary">MORE</Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default Package;
