import React, { useEffect, useState } from "react";
import { SvgIcon } from "@material-ui/core";
// import Icon from '@mdi/react';
// import { mdiPackageVariantClosed } from '@mdi/js';
import {
  Grid,
  CircularProgress,
  Chip,
  Typography,
  Avatar,
  Paper,
  List,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";
import {
  Receipt,
  DoneOutline,
  FlightTakeoff,
  LocalShippingTwoTone,
  LocalShipping,
} from "@material-ui/icons";
import { myStyles } from "../utils/config";
import { makeStyles } from "@material-ui/core/styles";

function Package(props) {
  const thisStyle = makeStyles(() => ({
    colorReceived: {
      color: "grey",
      // backgroundColor: "grey"
      borderColor: "grey",
      // color:"white"
    },
    colorInTransit: {
      color: "orange",
      borderColor: "orange",
      // backgroundColor: "orange"
      // color:"white"
    },
    colorReady: {
      color: "blue",
      // color:"white"
    },
    colorDelivered: {
      color: "green",
      // color:"white"
    },
    paper: {
      margin: 15,
      textAlign: "left",
      background: "white",
      padding:10
      // color: theme.palette.text.secondary,
    },
  }));
  const classes = myStyles();
  const css = thisStyle();
  const [packageType, setPackageType] = useState("received");


  const type = (value) => {
    switch (value) {
      case "received":
        return {
          css: css.colorReceived,
          icon: (
            <SvgIcon>
              <path d="M20,2H4C3,2,2,2.9,2,4v3.01C2,7.73,2.43,8.35,3,8.7V20c0,1.1,1.1,2,2,2h14c0.9,0,2-0.9,2-2V8.7c0.57-0.35,1-0.97,1-1.69V4 C22,2.9,21,2,20,2z M15,14H9v-2h6V14z M20,7H4V4h16V7z" />
            </SvgIcon>
          ),
        };
      case "intransit":
        return { css: css.colorInTransit, icon: <FlightTakeoff /> };
      case "ready":
        return { css: css.colorReady, icon: <Receipt /> };
      case "delivered":
        return { css: css.colorDelivered, icon: <DoneOutline /> };
      default:
        return { css: css.colorReceived, icon: "" };
    }
  };

  useEffect(() => {
    setPackageType(props.data.type);
  }, [props.data.type]);
  return (
    <div>
      <Paper className={css.paper}>
        <Grid container spacing={2} style={{flexGrow: 1}}>
          <Grid item xs={12}>
            <div style={{ padding: 6, textAlign: "center" }}>
              <Typography component="h6" style={{ fontWeight: 600 }}>
                FOOTWEAR
              </Typography>
              <small>87925790342858943</small>
            </div>
            <Divider variant="middle" />
          </Grid>

          <Grid item xs={12} >
           
          </Grid>
          <Grid item xs={9}>

        </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default Package;
