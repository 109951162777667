import { makeStyles } from "@material-ui/core/styles";
import { deepOrange, deepPurple } from '@material-ui/core/colors';

export const settings = {
  name: "ShipBiz",
};

const drawerWidth = 240;
export const myStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  title: {
    fontSize:"1.3em",
    fontWeight:500,
    color:"#333"
  },
  gridTitle: {
    display: "inline",
    marginTop:"14px"
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: "#05035A",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  //   toolbar: theme.mixins.toolbar,
  toolbar: {
    marginTop: 65,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor:"#F6F5F5"
  },

  //Paper
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },

  
 //Dash
  packStatusPanel: {
    color:'white',
    // flexGrow: 1,
    // display:'flex',
    // '& > *': {
    //   margin: theme.spacing(1),
    // },
    // border:"1px solid blue"
  },

  purpleTitle:{
    fontWeight:500,
    fontSize:"1.2em",
    color: "#2D0C57"
  },
  //MessageBoard
  inline: {
    display: 'inline',
  },
}));
