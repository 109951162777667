import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Nav from "./components/Nav";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import Packages from "./pages/Packages";
import SignUp from "./pages/SignUp";

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route path="/account" exact>
            <Nav comp={<Account />} />
          </Route>
          <Route path="/dashboard" exact>
            <Nav comp={<Dashboard />} />
          </Route>
          <Route path="/packages" exact>
            <Nav comp={<Packages />} />
          </Route>
          <Route path="/signup" exact>
            <SignUp />
          </Route>

          <Route path="/" exact>
            <Nav comp={<Dashboard />} />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
