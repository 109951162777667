import React from "react";
import {
  Grid,
  Paper,
  Avatar,
  Typography,
  Collapse,
  Button,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from "@material-ui/core";
import {
  FileCopy,
  ArrowDownward,
  KeyboardArrowDown,
  Edit,
  EditOutlined,
  EditAttributesRounded,
  EditRounded,
  KeyboardArrowUp,
} from "@material-ui/icons/";
//   import { myStyles } from "../utils/config";
import { makeStyles } from "@material-ui/core/styles";

function Mailbox() {
  const thisStyle = makeStyles(() => ({
    mycard: {
      borderRadius: 9,

      //   minHeight: 200,
      padding: 15,
      marginTop: 6,
      marginBottom: 0,
      color: "#f4f4f4",
      backgroundColor: "#21D4FD",
      //   backgroundImage: "linear-gradient(19deg, #21D4FD 0%, #B721FF 100%)",
      backgroundImage:
        "linear-gradient( 135.9deg,  rgba(109,25,252,1) 16.4%, rgba(125,31,165,1) 56.1% );",
    },
  }));
  const reveal = 50;
  // const classes = myStyles();
  const css = thisStyle();
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div>
        <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                style={{ fontWeight: 600, textAlign: "center", color:"#424242" }}
                onClick={handleChange}
              >
                Maliyah Malcolm 
              </Typography> 
             
            </Grid>
          </Grid>
      <Collapse
        in={checked}
        // collapsedHeight={reveal}
        // style={{ paddingBottom: 10, background: "red" }}
      >
          

        <div className={css.mycard}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <Typography variant="subtitle2">Address Line 1</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1">
                3750 W Oakland Blvd.
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">Address line 2</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1">
                MALI 90
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">City</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1">
                Lauderdale Lakes
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">State</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1">
                Florida
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle2">Zip</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography gutterBottom variant="subtitle1">
                33661
              </Typography>
            </Grid>

            <Grid item xs={12} style={{ textAlign: "center", color: "white" }}>
              <Divider
                style={{ background: "#8541a3" }}
              />
              <Button style={{ color: "white" }} startIcon={<FileCopy />}>
                Copy Address
              </Button>
            </Grid>
          </Grid>
        </div>
      </Collapse>
      <div
        style={{
          margin: "auto",
          textAlign: "center",
          paddingBottom: 10,
          paddingTop: 5,
        }}
      >
        <Button onClick={handleChange} style={{padding:0}}>
          {checked ? <KeyboardArrowUp/> : <KeyboardArrowDown />}
        </Button>
      </div>
    </div>
  );
}

export default Mailbox;
