import React from 'react'
function Account() {
    return(
        <div>
            <p>Account</p>
            {new Date().toLocaleTimeString()}
        </div>
    )
}
export default Account;
